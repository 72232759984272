import React from "react"
import Layout from "./Layout"
import LawyerCard from "./LawyerCard"
import PropTypes from "prop-types"

const LawyerGroup = ({ lawyers, title, seo }) => {
  return (
    <Layout>
      {seo}
      <div className="container mx-auto px-6 md:px-8 xl:px-0">
        <h1 className="mb-2 mt-8 text-left text-3xl font-bold text-gray-800 md:mt-16 md:text-center">
          法律010免費諮詢系統
        </h1>
        <p className="mb-12 text-left text-gray-500 md:text-center">
          以下是為您推薦的最佳律師，請選擇您想諮詢的律師，開始免費諮詢！
        </p>
        <div className="mb-8 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
          {lawyers.map(lawyer => {
            const { slug } = lawyer

            return (
              <div key={slug}>
                <LawyerCard
                  lawyer={lawyer}
                  title={`${title}執業律師`}
                  isButtonExisted={true}
                />
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

LawyerGroup.propTypes = {
  lawyers: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  seo: PropTypes.object.isRequired,
}

export default LawyerGroup
