import React from "react"
import Layout from "../components/Layout"
import Breadcrumb from "../components/Breadcrumb"
import { StaticImage } from "gatsby-plugin-image"
import { useAboutData } from "../data/useAboutData"
import logo from "../images/logo_laws010_color.svg"
import { AiFillCheckCircle } from "@react-icons/all-files/ai/AiFillCheckCircle"
import ReactMarkdown from "react-markdown"
import { useMetadata } from "../data/useMetadata"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt"
import CooperativeLawsSlider from "../components/CooperativeLawsSlider"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import LineButton from "../components/LineButton"

const About = () => {
  const { hero, achievement, serviceSop, contact, cooperativeLawsCompany } =
    useAboutData()
  const { siteUrl } = useMetadata()
  const crumbs = [
    { pathname: `/`, crumbLabel: "首頁" },
    { pathname: `/about`, crumbLabel: "關於我們" },
  ]

  const achievementList = achievement.map((item, index) => {
    return (
      <div className="flex items-start" key={index}>
        <AiFillCheckCircle className="mr-3 mt-1 h-6 w-6 shrink-0 text-gold-400" />
        <ReactMarkdown className="text-gray-700">{item}</ReactMarkdown>
      </div>
    )
  })
  const metaData = {
    title: "全台灣瀏覽人次最多的專業法律知識網站",
    description:
      "如何選擇線上免費法律諮詢？法律010提供全台超過70位、最專業的律師群，推薦最適合您的律師，並提供line和電話的諮詢服務，以最方便的方式解決法律問題！",
  }
  return (
    <Layout>
      <GatsbySeo
        title={metaData.title}
        description={metaData.description}
        openGraph={{
          title: metaData.title,
          description: metaData.description,
        }}
        canonical={`${siteUrl}/about`}
      />
      <div className="relative overflow-hidden">
        <div className="absolute z-0 h-full w-full opacity-75">
          <StaticImage
            src="../images/img_balance.jpg"
            alt="bill"
            objectFit="cover"
            className="h-full w-full"
            quality={80}
            placeholder="blurred"
            loading="eager"
          />
        </div>
        <div className="absolute z-0 h-full w-full bg-gray-700 opacity-75" />
        <div className="container relative z-10 mx-auto px-6 pb-24 pt-6 text-white md:px-8 xl:px-0">
          <Breadcrumb
            crumbs={crumbs}
            className="text-white"
            activeClassName="text-white"
          />
          <div className="mt-2 text-center">
            <h1 className="mb-3 text-3xl font-bold sm:mb-5 sm:text-5xl">
              {hero.title}
            </h1>
            <p className="mx-auto max-w-3xl text-xl sm:text-2xl">
              {hero.subTitle}
            </p>
          </div>
        </div>
      </div>
      <div className="bg-neutral-50">
        <div className="container mx-auto px-6 py-16 text-center sm:px-8 sm:py-24">
          <div className="items-center lg:mb-24">
            <div className="col-span-1">
              <img src={logo} alt="logo" className="mx-auto my-12 h-12" />
              <div className="mx-auto mb-16 flex max-w-fit flex-col gap-y-6 text-xl sm:mb-24 sm:text-2xl lg:text-left">
                {achievementList}
              </div>
            </div>
          </div>
          <div className="rounded-lg bg-white pb-32 pt-12 shadow-md">
            <p className="mx-auto mb-12 max-w-fit border-b-2 border-gold-500 text-3xl font-bold leading-normal text-gray-700">
              {cooperativeLawsCompany.title}
            </p>
            <CooperativeLawsSlider data={cooperativeLawsCompany.list} />
          </div>
        </div>
      </div>
      <div className="bg-white py-16 text-center sm:py-24">
        <div className="container mx-auto">
          <p className="mb-12 text-3xl font-bold leading-normal text-gray-700">
            {serviceSop.title}
          </p>
          <img
            src={serviceSop.imageVertical}
            alt={serviceSop.title}
            className="mx-auto block max-w-xs lg:hidden"
          />
          <img
            src={serviceSop.imageHorizontal}
            alt={serviceSop.title}
            className="mx-auto hidden w-full max-w-7xl lg:block"
          />
          <div className="flex items-center justify-center">
            <LineButton className="mt-12" text="免費法律諮詢" />
          </div>
        </div>
      </div>
      <div className="bg-neutral-50">
        <div className="container mx-auto px-6 py-16 md:flex md:py-24">
          <div className="mr-12">
            <p className="mb-5 text-3xl font-bold leading-normal text-gray-700">
              {contact.title}
            </p>
            <p className="mb-5 whitespace-nowrap text-lg leading-normal text-gray-500">
              {contact.subTitle}
            </p>
            <div className="mb-8 grid gap-y-3 text-lg leading-normal text-gray-700 md:mb-0">
              <div className="flex items-center">
                <FaMapMarkerAlt className="mr-4 text-gold-500" />
                <p>台北市松山區復興北路167號7樓之2</p>
              </div>
              <div className="flex items-center">
                <FaPhoneAlt className="mr-4 text-gold-500" />
                <p>02-7755-1985</p>
              </div>
              <div className="flex items-center">
                <FaEnvelope className="mr-4 text-gold-500" />
                <p>service@010.tw</p>
              </div>
            </div>
          </div>
          <iframe src={contact.mapIframe} className="h-full w-full md:h-auto" />
        </div>
      </div>
    </Layout>
  )
}

About.propTypes = {}

export default About
