import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { useMetadata } from "../data/useMetadata"
import useImages from "../data/useImage"

const SEO = () => {
  const { defaultTitle, titleTemplate, description, siteUrl, language } =
    useMetadata()

  const { og } = useImages()

  return (
    <>
      <GatsbySeo
        title={defaultTitle}
        titleTemplate={titleTemplate}
        description={description}
        siteUrl={siteUrl}
        language={language}
        openGraph={{
          url: siteUrl,
          title: defaultTitle,
          description: description,
          images: [
            {
              url: `${siteUrl}${og}`,
              width: 800,
              height: 600,
              alt: defaultTitle,
            },
          ],
          site_name: "法律010",
          type: "website",
          locale: "zh_tw",
        }}
      />
    </>
  )
}

export default SEO
