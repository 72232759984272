import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import PropTypes from "prop-types"

const FeaturedPost = ({ featuredPosts }) => {
  const display = featuredPosts.length >= 1 ? "grid" : "hidden"

  return (
    <div className={`${display} gap-8 md:container md:mx-auto lg:grid-cols-4`}>
      <div
        className={`mb-6 space-y-6 bg-white p-6 shadow-md md:mb-12 md:py-12 lg:col-span-3 lg:rounded-lg lg:py-6`}
      >
        <div className={"text-xl font-bold text-black md:text-2xl"}>
          編輯精選
        </div>
        <div className="grid gap-8 md:grid-cols-3">
          {featuredPosts.map(post => {
            const { topic, tag } = post

            return (
              <Link
                key={post.id}
                to={`/blog/${topic.slug}/${tag.slug}/${post.slug}`}
                data-test-id="feature-post"
              >
                <div className="grid space-y-3">
                  <GatsbyImage
                    image={post.cover.gatsbyImageData}
                    alt={post.cover.title}
                    className="rounded"
                  />

                  <div className="ml-3 space-y-3 lg:ml-0">
                    <p className="text-base font-bold text-gray-800 lg:text-xl">
                      {post.title}
                    </p>
                    <ReactMarkdown
                      className="line-clamp-4 text-base text-gray-500 md:line-clamp-5 lg:block lg:h-auto"
                      unwrapDisallowed={true}
                      disallowedElements={["link"]}
                    >
                      {post.excerpt.excerpt}
                    </ReactMarkdown>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

FeaturedPost.propTypes = {
  featuredPosts: PropTypes.arrayOf(PropTypes.object),
}

export default FeaturedPost
