import { graphql, useStaticQuery } from "gatsby"

const useImages = () => {
  const data = useStaticQuery(graphql`
    {
      og: file(relativePath: { eq: "og.jpg" }) {
        publicURL
      }
      logo: file(relativePath: { eq: "logo_laws010_color.svg" }) {
        publicURL
      }
      logoWhite: file(relativePath: { eq: "logo_laws010_white.svg" }) {
        publicURL
      }
    }
  `)
  return {
    og: data.og.publicURL,
    logo: data.logo.publicURL,
    logoWhite: data.logoWhite.publicURL,
  }
}

export default useImages
