import { graphql, useStaticQuery } from "gatsby"
import img_step_service_m from "../images/img_step_service_m.svg"
import img_step_service_d from "../images/img_step_service_d.svg"

export const useAboutData = () => {
  const lawsList = useStaticQuery(graphql`
    {
      allContentfulCoopLawsCompany {
        nodes {
          logo {
            url
            title
          }
        }
      }
    }
  `)
  const hero = {
    title: "關於我們",
    subTitle:
      "法律010透過更多科技協助民眾更容易找到適合的法律服務，選擇優質的律師解決法律問題。",
  }
  const achievement = [
    "網站瀏覽人次超過**1,000萬**",
    "已為超過**10,000位**有需求的用戶推薦律師",
  ]

  const serviceSop = {
    title: "服務流程",
    imageVertical: img_step_service_m,
    imageHorizontal: img_step_service_d,
  }

  const cooperativeLawsCompany = {
    title: "合作律所",
    list: lawsList.allContentfulCoopLawsCompany.nodes[0].logo,
  }
  const contact = {
    title: "聯絡資訊",
    subTitle: "如您有任何疑問，請由以下方式聯絡我們",
    mapIframe:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.4133665851155!2d121.54232869861094!3d25.053974986714376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abc03b52d197%3A0x960ebec4f51879d1!2z5ZaG5b6L5rOV5b6L5LqL5YuZ5omA!5e0!3m2!1szh-TW!2stw!4v1607505742757!5m2!1szh-TW!2stw",
  }

  return {
    hero,
    achievement,
    serviceSop,
    cooperativeLawsCompany,
    contact,
  }
}
