import { graphql, useStaticQuery } from "gatsby"

export const useTopicsTags = () => {
  return useStaticQuery(graphql`
    query TopicAndTag {
      allContentfulTopic(sort: { order: DESC }) {
        nodes {
          id
          title
          slug
          order
          icon {
            file {
              url
            }
          }
          tag {
            order
            id
            title
            slug
            topic {
              slug
            }
          }
        }
      }
    }
  `)
}
