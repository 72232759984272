import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import icon404 from "../images/icon_404.svg"

const NotFoundPage = () => (
  <Layout>
    <div className="container mx-auto h-full w-full py-20 text-center">
      <img className="mx-auto" src={icon404} alt="404icon" loading="lazy" />
      <p className="my-12 text-center text-xl font-bold text-gray-500 md:text-2xl">
        此頁面不存在
      </p>
      <Link
        to="/"
        className="rounded-full bg-gold-500 px-6 py-2 text-xl font-extrabold text-white sm:text-2xl"
      >
        回首頁
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
