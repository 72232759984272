import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { BlogJsonLd, GatsbySeo } from "gatsby-plugin-next-seo"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import CTA from "./CTA"
import Card from "./Card"
import TagsCloud from "./TagsCloud"
import { useMetadata } from "../data/useMetadata"
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share"
import PropTypes from "prop-types"
import Author from "./Author"
import PopUpModal from "./PopUpModal"
import useScrollPosition from "../data/useScrollPosition"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const BlogPost = ({ post, contents, image, isConversionPost = true }) => {
  const { siteUrl } = useMetadata()
  const [modalIsOpen, setIsOpen] = useState(false)

  const canonical =
    post.isPublic || (post.fields && post.fields.isPublic)
      ? `${siteUrl}/blog/${post.topic.slug}/${post.tag.slug}/${post.slug}`
      : ""
  const tags = post.topic.tag

  const components = {
    img({ ...props }) {
      return (
        <img
          {...props}
          alt={post.title}
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      )
    },
  }
  const { scrollToBottom, scrollBlockRef } = useScrollPosition()
  let popCount

  const popupLocalStorageSetting = (popCount, popup_type) => {
    setIsOpen(true)
    popCount += 1
    localStorage.setItem("popup_open", "true")
    localStorage.setItem("popup_type", popup_type)
    localStorage.setItem("popup_count", popCount.toString())
  }

  // 轉化文章才會觸發彈窗
  // 滑到文章最底部會觸發彈窗
  useEffect(() => {
    popCount = Number(localStorage.getItem("popup_count")) || 0
    const popupOpen = localStorage.getItem("popup_open") || "false"
    if (
      scrollToBottom &&
      popCount < 2 &&
      popupOpen === "false" &&
      isConversionPost
    ) {
      popupLocalStorageSetting(popCount, "scroll_bottom")
    }
  }, [scrollToBottom])

  // 進入部落格頁20秒後會觸發彈窗
  useEffect(() => {
    popCount = Number(localStorage.getItem("popup_count")) || 0
    if (popCount < 2 && isConversionPost) {
      setTimeout(() => {
        const popupOpen = localStorage.getItem("popup_open") || "false"
        if (popupOpen === "false") {
          popupLocalStorageSetting(popCount, "time")
        }
      }, 20 * 1000)
    }
  }, [])

  const alertTitle = "※網站聲明："
  const alertList = [
    "著作權由「喆律法律事務所」所有，非經正式書面授權，不得任意使用。",
    "文章資料內容僅供參考，不宜直接引用為主張或訴訟用途，具體個案仍請洽詢專業律師。",
    "法令具時效性，文章內容及所引用資料，請自行查核法令動態及現行有效之實務見解。",
  ]
  const alertContent = alertList.map((item, index) => {
    return <li key={index}>{item}</li>
  })

  return (
    <>
      <GatsbySeo
        title={post.title}
        description={
          (post.seo && post.seo.metaDescription) ||
          (post.seo.fields && post.seo.fields.metaDescription)
        }
        canonical={canonical}
        openGraph={{
          title: (post.seo && post.seo.metaTitle) || post.title,
          description:
            (post.seo && post.seo.metaDescription) ||
            (post.seo.fields && post.seo.fields.metaDescription),
          url: canonical,
          images: [
            {
              url: `https:${
                post.cover?.file?.url || post.cover?.fields?.file.url
              }`,
              width: 800,
              height: 600,
              alt:
                (post.seo && post.seo.metaTitle) ||
                post.cover.title ||
                (post.cover.fields && post.cover.fields.title),
            },
          ],
          type: "article",
          article: {
            authors: [
              post.author.name ||
                (post.author.fields && post.author.fields.name),
            ],
            tags: [
              post.tag.title || (post.tag.fields && post.tag.fields.title),
            ],
          },
        }}
      />
      <BlogJsonLd
        url={canonical}
        headline={post.title}
        title={post.title}
        images={`https:${
          post.cover?.gatsbyImageData?.images.fallback.src ||
          post.cover?.fields?.file.url
        }
          `}
        dateModified={post.updatedAt}
        authorName={
          post.author.name || (post.author.fields && post.author.fields.name)
        }
        description={(post.excerpt && post.excerpt.excerpt) || post.excerpt}
        posts={[
          {
            headline: post.title,
            image: `https:` + post.cover?.file?.url,
            author: post.author.name || post.author?.fields.name,
          },
        ]}
      />
      <PopUpModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      <div
        className="container mx-auto mb-6 grid grid-cols-4 gap-8 rounded-lg md:mb-12"
        ref={scrollBlockRef}
      >
        <div className="col-span-4 bg-white p-6 shadow-md lg:col-span-3">
          <h1 className="container mx-auto text-2xl font-bold text-gray-800 md:text-4xl">{`${post.title} - 法律010`}</h1>
          <div className="container mx-auto my-6">
            <Link
              to={`/blog/${post.topic.slug || post.topic.fields.slug}/${
                post.tag.slug || post.tag.fields.slug
              }`}
              className="rounded-full border border-solid border-gray-500 p-2 text-gray-500 hover:border-gold-500 hover:text-gold-600"
            >
              {post.tag.title || post.tag.fields.title}
            </Link>
            <div className="mt-6 flex">
              <GatsbyImage
                image={post.author.image.gatsbyImageData}
                alt={post.author.name}
                className="z-10 mr-4 h-12 w-12 rounded-full"
              />
              <div>
                <p className="mb-1 font-bold text-gray-800">
                  {post.author.name || post.author.fields.name}
                </p>
                <p className="text-sm text-gray-500">{post.updatedAt} 更新</p>
              </div>
            </div>
          </div>
          <div className="container mx-auto">
            <div className="markdown">
              {image}
              <section>
                <ReactMarkdown>
                  {(post.excerpt && post.excerpt.excerpt) || post.excerpt}
                </ReactMarkdown>
              </section>
            </div>
            <details className="mt-6 bg-gray-100 p-4 text-gray-700" open>
              <summary className="border-b-2 pb-2 text-gray-700">目錄</summary>
              <ul className="mb-0 list-none pl-2 pt-1">
                {post.contents.map((content, index) => {
                  if (
                    content.displayTitle ||
                    (content.fields && content.fields.displayTitle)
                  ) {
                    return (
                      <li
                        className="mb-0 py-1 text-sm lg:text-base"
                        key={content.id || content.sys.id}
                      >
                        <AnchorLink
                          to={`/blog/${post.topic.slug}/${post.tag.slug}/${post.slug}#content-${index}`}
                          className="block w-fit text-gray-700 underline-offset-4 hover:underline"
                          stripHash
                        >
                          {(content.fields && content.fields.title) ||
                            content.title}
                        </AnchorLink>
                      </li>
                    )
                  }
                })}
              </ul>
            </details>
            <div className="markdown">
              {contents.map((content, index) => {
                const titleDisplayed =
                  content.displayTitle ||
                  (content.fields && content.fields.displayTitle)
                    ? "block"
                    : "hidden"
                return (
                  <div key={content.id || content.sys.id} className="mt-8">
                    <h2
                      className={`${titleDisplayed} mb-4 mt-8 text-gray-700`}
                      id={`content-${index}`}
                    >
                      {content.title || content.fields.title}
                    </h2>
                    {content.cta ? (
                      <CTA ctas={content.cta} ctaTitle={content.title} />
                    ) : (
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        components={components}
                      >
                        {(content.fields && content.fields.content) ||
                          (content.content && content.content.content)}
                      </ReactMarkdown>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="container mx-auto my-6">
            <Link
              to={`/blog/${post.topic.slug || post.topic.fields.slug}/${
                post.tag.slug || post.tag.fields.slug
              }`}
              className="rounded-full border border-solid border-gray-500 p-2 text-gray-500 hover:border-gold-500 hover:text-gold-600"
            >
              {post.tag.title || post.tag.fields.title}
            </Link>
          </div>
          <div className="flex items-center pt-4">
            <div className="mr-2">分享至</div>
            <FacebookShareButton
              url={canonical}
              quote={post.title}
              className="mx-2 inline-flex"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <FacebookMessengerShareButton
              url={canonical}
              quote={post.title}
              appId="260692155140220"
              className="mx-2 inline-flex"
            >
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
            <LineShareButton
              url={canonical}
              quote={post.title}
              className="mx-2 inline-flex"
            >
              <LineIcon size={32} round />
            </LineShareButton>
            <TelegramShareButton
              url={canonical}
              quote={post.title}
              className="mx-2 inline-flex"
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </div>
          <div className="mt-8 text-sm">
            <p className="mb-1 text-gray-700 font-bold">{alertTitle}</p>
            <ul className="list-disc pl-6 text-gray-500">{alertContent}</ul>
          </div>
        </div>
        <div className="hidden lg:col-span-1 lg:block">
          <Author author={post.author} className="mb-10" />
          <Card>
            <Card.Title>{post.topic.title}法律常識</Card.Title>
            <Card.Content className="lg:pt-0">
              <TagsCloud tags={tags} />
            </Card.Content>
          </Card>
        </div>
        <div data-test-id="blog-end" />
      </div>
    </>
  )
}

BlogPost.propTypes = {
  post: PropTypes.object,
  contents: PropTypes.array,
  image: PropTypes.element,
  isConversionPost: PropTypes.bool,
}

export default BlogPost
