import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const PostCollection = ({ title, posts }) => {
  const display = posts.length >= 1 ? "grid" : "hidden"

  return (
    <div className={`${display} gap-8 md:container md:mx-auto lg:grid-cols-4`}>
      <div
        className={`mb-6 space-y-6 bg-white p-6 shadow-md md:mb-12 md:py-12 lg:col-span-3 lg:rounded-lg lg:py-6`}
      >
        <div className={"text-xl font-bold text-black md:text-2xl"}>
          {title}
        </div>

        <div className="grid gap-0 md:grid-cols-3 md:gap-1">
          {posts.map(post => {
            const { topic, tag } = post

            return (
              <Link
                key={post.id}
                to={`/blog/${topic.slug}/${tag.slug}/${post.slug}`}
                className="mb-4 border-b-2 border-gray-100 pb-4 sm:border-b-0"
              >
                <div className="flex w-full space-x-2">
                  <div>
                    <GatsbyImage
                      image={post.cover.gatsbyImageData}
                      className="h-16 w-16 rounded lg:h-24 lg:w-24"
                      alt={post.cover.title}
                    />
                  </div>
                  <div className="text-gray-800">{post.title}</div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

PostCollection.propTypes = {
  title: PropTypes.string,
  posts: PropTypes.arrayOf(PropTypes.object),
}

export default PostCollection
