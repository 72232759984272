import React from "react"
import PropTypes from "prop-types"
const Arrow = props => {
  const { arrowIcon, onClick, arrowClass } = props

  const arrow = React.createElement(arrowIcon)
  return (
    <div
      className={`absolute z-10 flex h-12 w-12 max-w-xs cursor-pointer items-center justify-center rounded-full text-xl transition duration-100 ease-linear focus:outline-none ${arrowClass}`}
      onClick={onClick}
    >
      {arrow}
    </div>
  )
}

Arrow.propTypes = {
  arrowIcon: PropTypes.func,
  onClick: PropTypes.func,
  arrowClass: PropTypes.string,
}

export default Arrow
