import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { useMetadata } from "../data/useMetadata"
import ReactMarkdown from "react-markdown"
import Breadcrumb from "../components/Breadcrumb"
import Card from "../components/Card"
import TagsCloud from "../components/TagsCloud"
import Layout from "../components/Layout"
import PropTypes from "prop-types"

export const pageQuery = graphql`
  query tagQuery($slug: String!) {
    contentfulTag(slug: { eq: $slug }) {
      title
      slug
      description
      icon {
        title
        file {
          url
        }
      }
      seo {
        metaTitle
        metaDescription
        ogImage {
          file {
            url
          }
        }
      }
      topic {
        title
        slug
        cover {
          file {
            url
          }
        }
        tag {
          id
          title
          slug
          topic {
            slug
            title
          }
        }
      }
      post {
        id
        title
        slug
        isPublic
        excerpt {
          excerpt
        }
        cover {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            width: 920
            height: 610
          )
        }
      }
    }
  }
`

const TagTemplate = ({ data, location }) => {
  const { contentfulTag: tag } = data

  const posts = tag.post
  const tags = tag.topic.tag

  const { siteUrl } = useMetadata()

  const canonical = `${siteUrl}/blog/${tag.topic.slug}/${tag.slug}`

  const crumbs = [
    {
      crumbLabel: "首頁",
      pathname: "/",
    },
    { crumbLabel: tag.topic.title, pathname: `/blog/${tag.topic.slug}/all` },
    { crumbLabel: tag.title, pathname: `/blog/${tag.topic.slug}/${tag.slug}` },
  ]

  return (
    <Layout location={location}>
      <GatsbySeo
        title={tag.title}
        description={(tag.seo && tag.seo.metaDescription) || tag.description}
        canonical={canonical}
        openGraph={{
          title: (tag.seo && tag.seo.metaTitle) || tag.title,
          description: (tag.seo && tag.seo.metaDescription) || tag.description,
          url: canonical,
          images: [
            {
              url: `https:${tag.topic.cover.file.url}`,
              width: 800,
              height: 600,
              alt: tag.topic.title,
            },
          ],
        }}
      />
      <Breadcrumb crumbs={crumbs} />
      <div className="mx-auto grid grid-cols-12 gap-8 lg:container">
        <div className="col-span-12 lg:col-span-9">
          <div className="bg-gold-500 py-6 lg:flex lg:items-center lg:rounded-t">
            <div className="mx-auto lg:mx-0">
              <img
                src={`https:${tag.icon.file.url}`}
                alt={tag.icon.title}
                className="mx-auto text-white lg:mx-4"
                width="96"
                height="96"
                style={{ filter: "brightness(3)" }}
              />
            </div>
            <h1 className="text-center text-2xl font-semibold text-white lg:text-5xl">
              {tag.title}
            </h1>
          </div>
          <h2 className="bg-gold-100 p-6 text-gray-800 lg:rounded-b">
            {tag.description}
          </h2>
          <div className="my-10 grid grid-cols-1 gap-6 rounded-lg px-6 sm:gap-10 sm:px-12 md:grid-cols-2 lg:px-0">
            {posts &&
              posts.map(
                post =>
                  post.isPublic && (
                    <div
                      key={post.id}
                      className="rounded-lg bg-white shadow-md"
                    >
                      <Link
                        to={`/blog/${tag.topic.slug}/${tag.slug}/${post.slug}`}
                      >
                        <GatsbyImage
                          image={post.cover.gatsbyImageData}
                          className="col-span-3 rounded md:col-span-1"
                          alt={post.title}
                        />
                      </Link>
                      <div className="p-6">
                        <Link
                          to={`/blog/${tag.topic.slug}/${tag.slug}/${post.slug}`}
                        >
                          <h3 className="pb-3 text-2xl font-bold text-gray-800">
                            {post.title}
                          </h3>
                          <ReactMarkdown
                            className="pb-3 text-gray-500"
                            disallowedElements={["a"]}
                            unwrapDisallowed={true}
                          >
                            {post.excerpt.excerpt}
                          </ReactMarkdown>
                        </Link>
                        <Link
                          to={`/blog/${tag.topic.slug}/${tag.slug}/${post.slug}`}
                          className="text-gray-500"
                        >
                          閱讀更多
                        </Link>
                      </div>
                    </div>
                  ),
              )}
          </div>
        </div>
        <div className="hidden lg:col-span-3 lg:block">
          <Card>
            <Card.Title>{tag.topic.title}法律常識</Card.Title>
            <Card.Content className="lg:pt-0">
              <TagsCloud tags={tags} />
            </Card.Content>
          </Card>
        </div>
      </div>
    </Layout>
  )
}

TagTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default TagTemplate
