import React, { useState } from "react"
import Modal from "react-modal"
import { FaPlus } from "@react-icons/all-files/fa/FaPlus"
import ReactMarkdown from "react-markdown"
import { usePeople } from "../data/usePeople"
import { useLawyer } from "../data/useLawyer"
import PropTypes from "prop-types"
import logo from "../images/logo_laws010_color.svg"

const Telephone = ({ phone }) => {
  return (
    <>
      <p>預約諮詢專線：{phone}</p>
      <p>服務時間：週一至週五 9:00 - 18:00</p>
    </>
  )
}

const Line = ({ data }) => {
  const {
    content: { content },
    qrCode,
    lineId,
  } = data
  const url = qrCode?.file?.url
  // lawyers/landing 頁面的彈窗不希望顯示 Line 相關資訊
  // 若沒有 line 資訊則顯示 logo 圖片

  const lineInfo = lineId ? (
    <p className="text-center">LINE ID : {lineId}</p>
  ) : (
    <img src={logo} alt="logo" className="mx-auto" />
  )

  return (
    <>
      <ReactMarkdown>{content}</ReactMarkdown>
      <img
        src={url}
        alt="qrCode"
        className={`mx-auto h-60 w-60 p-5 ${url ? "" : "hidden"}`}
      />
      {lineInfo}
    </>
  )
}

const ModalShow = ({
  pathname,
  children,
  className,
  isAnimation,
  type = "line",
  phone,
}) => {
  const isLanding = pathname === "/lawyers/landing"
  const [modalIsOpen, setIsOpen] = useState(false)
  const { peopleContactInformation } = usePeople()
  const { lawyerContactInformation } = useLawyer()
  let data = isLanding ? lawyerContactInformation : peopleContactInformation

  const openModal = async () => {
    await setIsOpen(true)
  }

  const closeModal = async () => {
    await setIsOpen(false)
  }
  const isTel = type === "tel"
  const modalContent = isTel ? (
    <Telephone phone={phone} />
  ) : (
    <Line data={data} />
  )

  return (
    <>
      {isAnimation ? (
        <div onClick={openModal} className={className}>
          <div
            className={`${data.gtmClass} animation fixed bottom-6 right-6 z-10 flex h-24 w-24 cursor-pointer items-center justify-center rounded-full bg-gold-400 shadow transition duration-150 ease-in-out hover:bg-gold-600 md:bottom-8 md:right-8 md:h-28 md:w-28 lg:bottom-16 lg:right-16`}
          >
            <div className="font-bold leading-relaxed tracking-widest text-white">
              {children}
            </div>
          </div>
        </div>
      ) : (
        <div onClick={openModal} className={className}>
          {children}
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,.5)",
            display: "block",
            position: "fixed",
            top: 0,
            zIndex: 30,
          },
          content: {
            padding: "2rem",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <div className="text-black-800 flex justify-between text-2xl">
          <div>{isTel ? "預約律師諮詢" : data?.title}</div>
          <FaPlus
            className="mt-2 rotate-45 transform cursor-pointer text-gray-400 hover:text-gray-500 active:text-gray-600"
            onClick={async () => {
              await setIsOpen(false)
            }}
          />
        </div>
        <div className="border-b border-gray-300 pb-5" />
        <div className="mx-auto py-5 text-left text-gray-800">
          {modalContent}
        </div>
        <div className="border-b border-gray-300" />
        <div
          onClick={async () => {
            await setIsOpen(false)
          }}
          className="ml-auto mt-5 h-10 w-20 cursor-pointer bg-white text-center text-gray-700 hover:bg-gray-100 active:bg-gray-200"
        >
          <div className="px-4 py-2">關閉</div>
        </div>
      </Modal>
    </>
  )
}

Telephone.propTypes = {
  phone: PropTypes.string,
}
Line.propTypes = {
  data: PropTypes.object,
}
ModalShow.propTypes = {
  pathname: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  isAnimation: PropTypes.bool,
  contacts: PropTypes.object,
  type: PropTypes.string,
  phone: PropTypes.string,
  isCornerBtn: PropTypes.bool,
}

export default ModalShow
