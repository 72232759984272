import React from "react"
import { Link } from "gatsby"
import useImage from "../data/useImage"
import Button from "./Button"
import Modal from "./Modal"
import Menu from "./Menu"
import PropTypes from "prop-types"

const Navbar = ({ pathname }) => {
  const { logo } = useImage()
  const isLanding = pathname === "/lawyers/landing"

  const Logo = () => (
    <Link to="/">
      <img
        src={logo}
        alt={"logo"}
        className="cursor-pointer"
        height="28"
        width="148"
      />
    </Link>
  )

  const LogoCard = ({ destination, title }) => (
    <Link
      to={destination}
      className="m-auto hidden text-gray-800 hover:text-gray-600 lg:block"
    >
      {title}
    </Link>
  )

  LogoCard.propTypes = {
    destination: PropTypes.string,
    title: PropTypes.string,
  }

  const NavbarList = ({ children, pathname }) => {
    return (
      <div className="fixed z-20 h-16 w-full bg-white px-6 shadow sm:px-8">
        <div className="container mx-auto flex h-full items-center justify-between">
          <Logo />
          <div className="grid grid-cols-1 lg:grid-cols-5">
            <Menu pathname={pathname} /> {/* 這個 Menu 是手機版 */}
            <LogoCard destination="/lawyers/landing" title="我是律師" />
            <LogoCard destination="/find_lawyer" title="律師名錄" />
            <LogoCard destination="/about" title="關於我們" />
            {children}
          </div>
        </div>
      </div>
    )
  }

  NavbarList.propTypes = {
    children: PropTypes.object,
    location: PropTypes.any,
  }

  // isLanding -> 外層是 Modal，內層文字是「加入我們」
  const ctaButton = React.createElement(
    Modal,
    { pathname: pathname },
    React.createElement(
      Button,
      {
        className: `hidden lg:block my-3 mx-auto contact`,
      },
      isLanding ? "加入我們" : "免費法律諮詢",
    ),
  )

  return <NavbarList pathname={pathname}>{ctaButton}</NavbarList>
}

Navbar.propTypes = {
  pathname: PropTypes.string,
}

export default Navbar
