import { graphql, useStaticQuery } from "gatsby"

export const useMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          siteUrl
          siteName
          defaultTitle
          titleTemplate
          description
          language
          gtmId
          lineLink
          isTestingSite
          isCloneSite
          isCloneB
        }
      }
    }
  `)
  const { siteMetadata } = site
  return siteMetadata
}
