import React from "react"
import useSWR from "swr"
import fetch from "unfetch"
import Loading from "../../components/Loading"
import { Link } from "gatsby"

const PreviewPosts = () => {
  const fetcher = url => fetch(url).then(r => r.json())
  const api = `https://${process.env.GATSBY_CONTENTFUL_HOST}/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}&content_type=post`
  const { data, error } = useSWR(api, fetcher)

  const isLoading = !data && !error

  if (!isLoading) {
    if (!error) {
      return (
        <div className="container mx-auto mt-6 px-6 sm:px-8">
          {data.items.map(item => {
            return (
              <Link key={item.sys.id} to={`/preview?id=${item.sys.id}`}>
                <p className="mb-4 font-bold">{item.fields.title}</p>
              </Link>
            )
          })}
        </div>
      )
    } else {
      return <div>{JSON.stringify(error)}</div>
    }
  }
  return <Loading />
}
export default PreviewPosts
