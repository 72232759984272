import React from "react"
import PropTypes from "prop-types"
import Modal from "./Modal"
import Button from "./Button"
import { useMetadata } from "../data/useMetadata"

const LineButton = ({ text = "免費法律諮詢", className = "" }) => {
  const { lineLink } = useMetadata()
  return (
    <div className={className}>
      <Modal className="hidden sm:block">
        <Button className="contact py-3 sm:text-2xl">{text}</Button>
      </Modal>
      <a
        className="block sm:hidden"
        href={lineLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className="contact py-3 text-xl">{text}</Button>
      </a>
    </div>
  )
}

LineButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
}

export default LineButton
