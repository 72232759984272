import React from "react"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { useMetadata } from "../../data/useMetadata"
import LawyerGroup from "../../components/LawyerGroup"
import PropTypes from "prop-types"

export const pageQuery = graphql`
  query City($slug: String!) {
    contentfulCity(slug: { eq: $slug }) {
      slug
      title
      lawyer {
        order
        slug
        name
        lineLink
        isPublished
        introduction {
          introduction
        }
        profile {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            width: 128
            height: 128
          )
        }
      }
    }
  }
`

const City = ({ data }) => {
  const { contentfulCity: city } = data
  const { lawyer: lawyers, title } = city

  const { siteUrl } = useMetadata()

  const seo = <GatsbySeo canonical={`${siteUrl}/city/${city.slug}`} />
  const isPublishedList = lawyers
    .filter(lawyer => lawyer.isPublished)
    .sort((a, b) => a.order - b.order)

  return <LawyerGroup title={title} lawyers={isPublishedList} seo={seo} />
}

City.propTypes = {
  data: PropTypes.object,
}

export default City
