import React from "react"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { StaticImage } from "gatsby-plugin-image"
import { useMetadata } from "../../data/useMetadata"
import { useLawyer } from "../../data/useLawyer"
import Button from "../../components/Button"
import Layout from "../../components/Layout"
import LawyerCard from "../../components/LawyerCard"
import Modal from "../../components/Modal"
import PropTypes from "prop-types"

export const pageQuery = graphql`
  query LandingQuery {
    featuredCollections: allContentfulFeatures(limit: 5, sort: { order: ASC }) {
      nodes {
        id
        title
        excerpt
        icon {
          file {
            url
          }
        }
      }
    }
    advantagedCollections: allContentfulAdvantage(
      limit: 4
      sort: { order: ASC }
    ) {
      nodes {
        id
        title
        amount
        excerpt
      }
    }
    lawyerCollections: allContentfulLawyer(
      limit: 3
      sort: { order: ASC }
      filter: { isPublished: { eq: true } }
    ) {
      nodes {
        id
        name
        firmName
        introduction {
          introduction
        }
        profile {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            width: 128
            height: 128
          )
        }
      }
    }
  }
`

const Landing = ({ location, data }) => {
  const { advantagedCollections, featuredCollections, lawyerCollections } = data
  const advantages = advantagedCollections.nodes
  const features = featuredCollections.nodes
  const lawyers = lawyerCollections.nodes
  const { lawyerContactInformation } = useLawyer()
  const { gtmClass } = lawyerContactInformation
  const pathname = location.pathname
  const { siteUrl } = useMetadata()

  const Contact = ({ className, content }) => {
    return (
      <div className={`${className} mt-12 sm:mb-3 md:mb-8 md:mt-24`}>
        <Modal pathname={pathname}>
          <Button
            className={`${gtmClass} px-8 py-3 text-lg font-bold md:text-2xl`}
          >
            {content}
          </Button>
        </Modal>
      </div>
    )
  }

  Contact.propTypes = {
    className: PropTypes.string,
    content: PropTypes.string.isRequired,
  }

  const HeadingTitle = ({ title }) => {
    return (
      <div className="mb-12 border-l-4 border-gold-500 pl-2 sm:mb-16 md:border-l-0 md:pl-0">
        <p className="text-left text-3xl font-bold text-gray-800 sm:text-4xl md:text-center">
          <span className="h-1 border-gold-500 md:border-b-4 md:pb-2 md:pl-0">
            {title}
          </span>
        </p>
      </div>
    )
  }

  HeadingTitle.propTypes = {
    title: PropTypes.string,
  }
  const title = "法律010 - 全台最大律師服務網站！"
  const description =
    "正在辛苦打造律師個人品牌嗎？法律010協助您爭取更多曝光機會，增加詢問度！在法律010不僅有專屬個人網址，方便有法律需求的民眾找到您，更有對外合作的機會，讓更多人認識您的認真、您的好！"
  const buttonContent = "成為合作律師"
  return (
    <Layout location={location} className="md:text-2xl">
      <GatsbySeo
        title={title}
        description={description}
        openGraph={{
          title: title,
          description: description,
        }}
        canonical={`${siteUrl}/lawyers/landing`}
      />
      <div className="bg-gradient-to-b from-white to-gray-100">
        <div className="container mx-auto mb-0 grid grid-cols-2 px-0 pb-0 pt-12 text-gray-800 md:px-8 md:pb-12 md:pt-24 lg:px-12 lg:pb-24 xl:px-0">
          <div className="col-span-2 px-6 py-0 md:mx-0 lg:col-span-1 lg:mr-6 lg:py-40">
            <h1 className="mb-3 text-left text-3xl font-black text-gray-700 sm:mb-6 md:text-center md:text-5xl lg:text-left">
              <span className="text-gold-500">每月300,000位</span>
              民眾在法律010找律師!
            </h1>
            <Contact
              content={buttonContent}
              className="text-center lg:text-left"
            />
          </div>
          <div className="col-span-2 w-full lg:inset-y-0 lg:right-0 lg:col-span-1">
            <div className="my-0 hidden h-[660px] sm:block">
              <div className="absolute">
                <StaticImage
                  className="h-[660px]"
                  src="../../images/img_landing_lawyer.png"
                  alt="法律010"
                  objectFit="cover"
                  objectPosition="left top"
                  height={660}
                  quality={100}
                  loading="eager"
                />
              </div>
            </div>
            <div className="my-0 block sm:hidden">
              <StaticImage
                src={"../../images/img_landing_lawyer_mobile.png"}
                alt="法律010"
                width={640}
                height={640}
                objectFit="cover"
                objectPosition="left top"
                quality={100}
                loading="eager"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-6 py-16 md:px-0 md:py-24">
        <div className="container mx-auto">
          <HeadingTitle title="加入法律010優勢" />
          <div className="grid grid-cols-1 gap-16 text-center sm:grid-cols-2 sm:gap-x-0 sm:gap-y-16 lg:gap-x-0 lg:gap-y-20">
            {advantages.map((advantage, index) => {
              const { id, title, amount, excerpt } = advantage
              const borderLine =
                index % 2 === 0 ? "sm:border-r sm:border-gray-300" : ""
              return (
                <div key={id} className={borderLine}>
                  <div className="text-gray-500">{title}</div>
                  <div className="teko-font text-5xl font-bold text-gold-500">
                    {amount}
                  </div>
                  <div className="text-lg font-medium text-gray-800">
                    {excerpt}
                  </div>
                </div>
              )
            })}
          </div>
          <Contact content="加入法律010" className="text-center" />
        </div>
      </div>
      <div className="bg-white px-6 py-16 md:py-24">
        <div className="container mx-auto">
          <HeadingTitle title="法律010提供律師最需要的功能" />
          <div className="mt-12 grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
            {features.map(feature => {
              const { id, title, excerpt, icon } = feature
              return (
                <div key={id}>
                  <img
                    src={icon.file.url}
                    alt={title}
                    loading="lazy"
                    width="128"
                    height="128"
                    className="mx-auto mb-5 block sm:hidden"
                  />
                  <img
                    src={icon.file.url}
                    alt={title}
                    loading="lazy"
                    width="160"
                    height="160"
                    className="mx-auto mb-5 hidden sm:block"
                  />
                  <p className="mb-3 text-center text-lg font-bold text-gold-500 sm:text-xl">
                    {title}
                  </p>
                  <p className="text-left text-gray-800 md:text-center">
                    {excerpt}
                  </p>
                </div>
              )
            })}
          </div>
          <Contact content={buttonContent} className="text-center" />
        </div>
      </div>
      <div className="flex justify-center bg-gold-500 text-white">
        <div className="container px-6 py-16 md:px-8 md:py-24 lg:px-0">
          <div className="flex flex-col items-center justify-center lg:flex-row">
            <div className="lg:mr-12">
              <StaticImage
                src={"../../images/logo_laws010_circle.png"}
                alt="法律010"
                width={160}
                height={160}
                placeholder="blurred"
              />
            </div>
            <div className="mt-12 lg:mt-0">
              <div className="mb-3 text-3xl font-bold text-white md:text-5xl">
                加入全台灣最大的法律服務網站！
              </div>
              <div className="text-xl font-medium text-white md:text-3xl">
                Be a part of the largest legal network
              </div>
            </div>
          </div>
          <div className="text-center">
            <Modal pathname={pathname}>
              <button
                className={`${gtmClass} hover:bg-white-400 focus:border-white-600 focus:shadow-outline-indigo active:bg-white-600 mt-12 rounded-md border border-transparent bg-white px-8 py-3 text-center text-lg font-bold leading-6 text-gold-500 transition duration-150 ease-in-out focus:outline-none md:text-2xl`}
              >
                {buttonContent}
              </button>
            </Modal>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-6 py-16 text-gray-800 md:py-24">
        <HeadingTitle title="律師推薦" />
        <div className="container mx-auto mb-0 mt-12 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-12">
          {lawyers.map(lawyer => {
            const { id, firmName } = lawyer
            return (
              <div key={id}>
                <LawyerCard
                  lawyer={lawyer}
                  title={firmName}
                  isButtonExisted={false}
                />
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

Landing.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export default Landing
