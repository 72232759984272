import React from "react"
import Modal from "./Modal"
import { usePeople } from "../data/usePeople"
import { useLawyer } from "../data/useLawyer"
import PropTypes from "prop-types"

const FixedCTAButton = ({ pathname, className = "", displayFixCta = true }) => {
  const isLanding = pathname === "/lawyers/landing"
  const { peopleContactInformation } = usePeople()
  const { lawyerContactInformation } = useLawyer()
  let data = isLanding ? lawyerContactInformation : peopleContactInformation
  const { gtmClass, nameOne, nameTwo, lineAt } = data

  const RoundedButton = () => {
    return (
      <div className="text-center">
        <a
          href={lineAt}
          target="_blank"
          rel="noopener noreferrer"
          className="corner_button block lg:hidden"
        >
          <div
            className={`${gtmClass} animation fixed bottom-6 right-6 z-10 flex h-24 w-24 cursor-pointer items-center justify-center rounded-full bg-gold-400 shadow transition duration-150 ease-in-out hover:bg-gold-600 md:bottom-8 md:right-8 md:h-28 md:w-28 lg:bottom-16 lg:right-16`}
          >
            <div className="font-bold leading-relaxed tracking-widest text-white">
              <p className={`md:text-2xl ${gtmClass}`}>{nameOne}</p>
              <p className={`md:text-base ${className} ${gtmClass}`}>
                {nameTwo}
              </p>
            </div>
          </div>
        </a>
        <div className="corner_button hidden lg:block">
          <Modal pathname={pathname} isAnimation={true}>
            <p className={`text-2xl ${gtmClass}`}>{nameOne}</p>
            <p className={`text-base ${className} ${gtmClass}`}>{nameTwo}</p>
          </Modal>
        </div>
      </div>
    )
  }

  const LandingButton = () => {
    return (
      <div className="corner_button">
        <Modal isCornerBtn={true} pathname={pathname} isAnimation={true}>
          <p className={`md:text-2xl ${gtmClass}`}>{nameOne}</p>
          <p className={`md:text-2xl ${className} ${gtmClass}`}>{nameTwo}</p>
        </Modal>
      </div>
    )
  }

  const ctaButton = isLanding ? <LandingButton /> : <RoundedButton />

  return <>{displayFixCta && ctaButton}</>
}

FixedCTAButton.propTypes = {
  data: PropTypes.any,
  pathname: PropTypes.string,
  className: PropTypes.string,
  displayFixCta: PropTypes.bool,
}

export default FixedCTAButton
