import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import TagsCloud from "./TagsCloud"

const TopicCard = ({ topic }) => {
  const { icon, title, slug, tag } = topic

  return (
    <div className="rounded-lg bg-white p-6 shadow sm:mx-0">
      <div className="flex items-center">
        <div className="mr-4 h-16 w-16 rounded-full bg-gold-100 p-2">
          <img src={icon.file.url} alt="icon" />
        </div>
        <Link
          to={`/blog/${slug}/all`}
          className="text-xl font-bold leading-normal text-gray-700 hover:underline"
        >
          {title}
        </Link>
      </div>
      <div className="my-4 border border-gray-200" />
      <div className="flex flex-wrap">
        <TagsCloud tags={tag} />
      </div>
    </div>
  )
}

TopicCard.propTypes = {
  topic: PropTypes.object,
}

export default TopicCard
