import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import Button from "./Button"

const LawyerCard = ({ lawyer, title, isButtonExisted }) => {
  const { slug, name, introduction, profile } = lawyer

  return (
    <div className="min-h-xs w-full rounded-lg bg-white p-6 shadow-md">
      <div className="flex items-center">
        <div className="h-16 w-16 overflow-hidden rounded-full">
          <GatsbyImage
            alt={name}
            image={profile.gatsbyImageData}
            imgStyle={{ borderRadius: "100%" }}
          />
        </div>
        <div className="ml-3">
          <div className="text-lg">{name} 律師</div>
          <div className="mt-2 text-base text-gray-500">{title}</div>
        </div>
      </div>
      <p className="mt-5 h-24 overflow-hidden">
        {introduction && introduction.introduction}
      </p>
      {isButtonExisted && (
        <Link
          to={`/lawyers/${slug}`}
          className="mt-5 block text-sm text-gray-500"
        >
          <Button className="w-full">更多資訊</Button>
        </Link>
      )}
    </div>
  )
}

LawyerCard.propTypes = {
  lawyer: PropTypes.object,
  title: PropTypes.any,
  isButtonExisted: PropTypes.bool,
}

export default LawyerCard
