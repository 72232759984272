import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import ReactMarkdown from "react-markdown"
import { useMetadata } from "../data/useMetadata"
import Breadcrumb from "../components/Breadcrumb"
import Card from "../components/Card"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { FaBuilding } from "@react-icons/all-files/fa/FaBuilding"
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle"
import { FaClock } from "@react-icons/all-files/fa/FaClock"
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"
import { FaQuoteRight } from "@react-icons/all-files/fa/FaQuoteRight"
import PropTypes from "prop-types"

export const pageQuery = graphql`
  query LawyerQuery($slug: String!) {
    contentfulLawyer(slug: { eq: $slug }) {
      slug
      name
      licenseNumber
      firmName
      firmAddress
      inPersonConsultation
      phoneConsultation
      businessHours {
        businessHours
      }
      excerpt {
        excerpt
      }
      introduction {
        introduction
      }
      education {
        education
      }
      workExperience {
        workExperience
      }
      practiceAreas {
        id
        title
      }
      serviceAreas {
        id
        title
      }
      profile {
        gatsbyImageData(
          quality: 100
          placeholder: BLURRED
          width: 384
          height: 384
        )
        file {
          url
        }
      }
    }
  }
`

const Lawyer = ({ location, data }) => {
  const { contentfulLawyer: lawyer } = data
  const {
    slug,
    name,
    licenseNumber,
    firmName,
    firmAddress,
    inPersonConsultation,
    phoneConsultation,
    businessHours,
    excerpt,
    introduction,
    education,
    workExperience,
    practiceAreas,
    serviceAreas,
    profile,
  } = lawyer
  const crumbs = [
    { pathname: `/`, crumbLabel: "首頁" },
    {
      pathname: `/lawyers/${slug}`,
      crumbLabel: `${name}律師`,
    },
  ]

  const title = `${name}律師`

  const { siteUrl } = useMetadata()
  const canonical = `${siteUrl}/lawyers/${slug}`

  return (
    <Layout location={location}>
      <div className="container mx-auto">
        <GatsbySeo
          title={title}
          description={introduction && introduction.introduction}
          canonical={canonical}
          openGraph={{
            title: title,
            description: introduction && introduction.introduction,
            url: canonical,
            images: [
              {
                url: `https:${profile.file.url}`,
                width: 800,
                height: 600,
                alt: title,
              },
            ],
          }}
        />
        <Breadcrumb crumbs={crumbs} />
        <div className="mb-12 grid grid-cols-3 gap-x-12">
          <div className="col-span-3 md:col-span-1">
            <Card>
              <Card.Content>
                <div className="text-lg font-bold uppercase leading-normal text-gold-500">
                  基本資料
                </div>
                <div className="text-center">
                  <GatsbyImage
                    image={profile.gatsbyImageData}
                    alt={name}
                    imgStyle={{ borderRadius: "100%" }}
                    className="my-6 h-48 w-48 rounded-full"
                  />
                </div>
                <h1 className="mb-2 text-center text-xl text-gray-800">
                  {name}律師
                  <span className="ml-2 inline-block text-gold-500">
                    <FaCheckCircle />
                  </span>
                </h1>
                <p className="mb-2 text-center text-sm text-gray-500">
                  {licenseNumber}
                </p>
                {firmName && (
                  <p className="mb-2 text-gray-500">
                    <span className="mr-2 inline-block text-gold-500">
                      <FaBuilding />
                    </span>
                    {firmName}
                  </p>
                )}
                {businessHours && (
                  <div className="mb-2 flex items-center text-gray-500">
                    <span className="mr-2 inline-block text-gold-500">
                      <FaClock />
                    </span>
                    <ReactMarkdown>{businessHours.businessHours}</ReactMarkdown>
                  </div>
                )}
                {firmAddress && (
                  <p className="text-gray-500">
                    <span className="mr-2 inline-block text-gold-500">
                      <FaMapMarkerAlt />
                    </span>
                    {firmAddress}
                  </p>
                )}
              </Card.Content>
            </Card>
          </div>
          <div className="col-span-3 mt-8 md:col-span-2 md:mt-0">
            {excerpt && (
              <Card className="mb-12 border border-gold-500">
                <Card.Content>
                  <FaQuoteRight className="mx-auto my-6 text-5xl text-gold-500" />
                  <ReactMarkdown className={"text-xl text-gold-500"}>
                    {excerpt.excerpt}
                  </ReactMarkdown>
                </Card.Content>
              </Card>
            )}
            <Card>
              <Card.Content>
                {introduction && (
                  <Section>
                    <Section.Title>律師介紹</Section.Title>
                    <Section.Content>
                      <ReactMarkdown>
                        {introduction?.introduction}
                      </ReactMarkdown>
                    </Section.Content>
                  </Section>
                )}
                <Section>
                  <Section.Title>學經歷</Section.Title>
                  <Section.Content>
                    <ReactMarkdown>{education?.education}</ReactMarkdown>
                    <ReactMarkdown>
                      {workExperience?.workExperience}
                    </ReactMarkdown>
                  </Section.Content>
                </Section>
                {practiceAreas && (
                  <Section>
                    <Section.Title>專業領域</Section.Title>
                    <Section.Content>
                      {practiceAreas.map((practiceArea, index) => {
                        const { id, title } = practiceArea
                        return index === 0 ? (
                          <span key={id}>{title}</span>
                        ) : (
                          <span key={id}> 、{title}</span>
                        )
                      })}
                    </Section.Content>
                  </Section>
                )}
                {serviceAreas && (
                  <Section>
                    <Section.Title>服務地區</Section.Title>
                    <Section.Content>
                      {serviceAreas.map((serviceArea, index) => {
                        const { id, title } = serviceArea
                        return index === 0 ? (
                          <span key={id}>{title}</span>
                        ) : (
                          <span key={id}> 、{title}</span>
                        )
                      })}
                    </Section.Content>
                  </Section>
                )}
                <Section>
                  <Section.Title>諮詢費用</Section.Title>
                  <Section.Content>
                    <span>現場諮詢：</span>
                    <span className="text-gray-500">
                      {inPersonConsultation}元/小時
                    </span>
                    <span className="ml-6">電話諮詢：</span>
                    <span className="text-gray-500">
                      {phoneConsultation}元/小時
                    </span>
                  </Section.Content>
                </Section>
              </Card.Content>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  )
}

Lawyer.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export default Lawyer
