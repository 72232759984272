import React, { useState } from "react"
import { Link } from "gatsby"
import LawyerCard from "./LawyerCard"
import PropTypes from "prop-types"

const RecommendedLawyer = ({
  data,
  destination,
  title,
  excerpt,
  about,
  className,
}) => {
  let lawyerList = []
  data.map(group => {
    const { isRecommended } = group
    if (isRecommended) {
      lawyerList.push(group)
    }
  })

  const [isSelected, setIsSelected] = useState(0)
  const groupSelected = lawyerList[isSelected]
  const lawyerSelected = groupSelected.lawyer.sort((a, b) => {
    return a.order - b.order
  })

  return (
    <div className={className}>
      <p className="mb-3 text-2xl font-bold text-gray-800 md:text-3xl">
        {title}
      </p>
      <p className="mb-12 text-base text-gray-500 md:text-xl">{excerpt}</p>
      <div className="mb-12 grid grid-cols-3 gap-y-6 md:grid-cols-4 lg:grid-cols-5">
        {data.map(group => {
          const { id, slug, title } = group
          return (
            <div className="text-gold-600" key={id}>
              <Link to={`/${destination}/${slug}`}>{title}</Link>
            </div>
          )
        })}
      </div>
      <div className="mb-8 border-b border-gray-200">
        <p className="mb-6">{about}</p>
        <nav className="no-scrollbar flex w-full overflow-x-scroll text-center text-sm">
          {lawyerList.map((lawyer, index) => {
            const { id, title } = lawyer
            const activeClassName =
              index === isSelected
                ? "text-gray-700 border-gold-600"
                : "text-gray-400"
            return (
              <div
                key={id}
                onClick={() => setIsSelected(index)}
                className={`${activeClassName} cursor-pointer whitespace-nowrap border-b-2 border-transparent px-4 py-2 hover:text-gray-700`}
              >
                {title}
              </div>
            )
          })}
        </nav>
      </div>
      <div className="container mx-auto grid grid-cols-1 gap-8 pb-16 md:grid-cols-2 md:gap-12 lg:grid-cols-3">
        {lawyerSelected.map((group, index) => {
          const title = groupSelected.title
          const { slug } = group
          if (index <= 4) {
            return (
              <div key={slug}>
                <LawyerCard
                  lawyer={group}
                  title={`${title}執業律師`}
                  isButtonExisted={true}
                />
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

RecommendedLawyer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  destination: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default RecommendedLawyer
