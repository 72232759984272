import React from "react"
import { Link } from "gatsby"
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare"
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"
import { FaLine } from "@react-icons/all-files/fa/FaLine"
import { useFooter } from "../data/useFooter"
import useImage from "../data/useImage"
import { useMetadata } from "../data/useMetadata"

const Footer = () => {
  const { logoWhite } = useImage()
  const { lineLink } = useMetadata()
  const { topics, relatedLinks } = useFooter()
  const topicList = topics.nodes.map(topic => {
    const { slug, title, id } = topic
    return (
      <Link to={`/blog/${slug}/all`} key={id} className="flex flex-col">
        {title}
      </Link>
    )
  })

  return (
    <div className="bg-gray-500 px-6 py-16 text-base text-white sm:px-8 sm:py-24">
      <div className="container mx-auto mb-6 grid grid-cols-1 text-left sm:grid-cols-2 lg:grid-cols-3">
        <div>
          <img
            className="pb-3 text-left"
            height="40"
            width="211"
            src={logoWhite}
            alt="零一零科技股份有限公司"
            loading="lazy"
          />
          <p className="pb-2">
            <span className="mr-2 inline-block">
              <FaMapMarkerAlt />
            </span>
            台北市松山區復興北路167號7樓之2
          </p>
          <p className="pb-2">
            <span className="mr-2 inline-block">
              <FaPhoneAlt />
            </span>
            02-7755-1985
          </p>
          <p className="pb-2">
            <span className="mr-2 inline-block">
              <FaEnvelope />
            </span>
            service@010.tw
          </p>
          <p className="pb-2">
            <Link to="/privacy">隱私權政策</Link>
          </p>
          <div className="flex">
            <a
              href="https://www.facebook.com/laws010"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookSquare size={32} />
            </a>
            <a
              href={lineLink}
              target="_blank"
              rel="noreferrer"
              className="ml-2"
            >
              <FaLine size={32} />
            </a>
          </div>
        </div>
        <div className="hidden sm:block">
          <p className="mb-3 text-xl">法律知識文章</p>
          {topicList}
        </div>
        <div className="hidden lg:block">
          <p className="mb-3 text-xl">相關連結</p>
          {relatedLinks.nodes.map(relatedLink => {
            const { name, url, id } = relatedLink
            return (
              <div key={id}>
                <a href={url} target="_blank" rel="noreferrer">
                  {name}
                </a>
              </div>
            )
          })}
        </div>
      </div>
      <div className="text-center">
        <p>
          Copyright © {new Date().getFullYear()} 喆律法律事務所 All rights
          reserved.
        </p>
      </div>
    </div>
  )
}
export default Footer
