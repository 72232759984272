import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { StaticImage } from "gatsby-plugin-image"
import { useMetadata } from "../data/useMetadata"
import ReactMarkdown from "react-markdown"
import Button from "../components/Button"
import Breadcrumb from "../components/Breadcrumb"
import Layout from "../components/Layout"
import RecommendedLawyer from "../components/RecommendedLawyer"
import PropTypes from "prop-types"

export const query = graphql`
  {
    cityCollection: allContentfulCity(sort: { order: ASC }) {
      nodes {
        id
        isRecommended
        title
        slug
        lawyer {
          ...lawyerData
        }
      }
    }
    practiceAreaCollection: allContentfulPracticeArea(sort: { order: ASC }) {
      nodes {
        id
        isRecommended
        title
        slug
        lawyer {
          ...lawyerData
        }
      }
    }
  }

  fragment lawyerData on ContentfulLawyer {
    slug
    name
    order
    introduction {
      introduction
    }
    profile {
      gatsbyImageData(
        quality: 100
        placeholder: BLURRED
        width: 128
        height: 128
      )
    }
  }
`

const FindLawyer = ({ location, data }) => {
  const { cityCollection, practiceAreaCollection } = data
  const cities = cityCollection.nodes
  const practiceAreas = practiceAreaCollection.nodes

  const { siteUrl } = useMetadata()

  const [city, setCity] = useState(null)
  const [cityUrl, setCityUrl] = useState("/find_lawyer")

  const crumbs = [
    { pathname: `/`, crumbLabel: "首頁" },
    { pathname: `/find_lawyer`, crumbLabel: "找律師" },
  ]

  useEffect(() => {
    if (city) {
      setCityUrl(`/city/${city}`)
    }
  }, [city])

  const lawyerBlogs = [
    `## 如何找律師？
「誰認識厲害的律師？」很常在臉書社團或是PTT上面看到想要找律師的貼文，但你知道嗎？其實
所謂厲害的律師不一定真的「適合」你。
我們提供找律師要把握的3大重點，讓您順利、快速找到適合您的好律師！
### 一、確認是否有律師資格？
不論是PTT網友推薦律師，或是身邊親友曾經委任過的律師，強烈建議先確認律師身份，透過法務部的律師查詢系統。在法律010上面的律師，都是經過認證具有律師資格的。

### 二、瞭解律師經驗
雖然每個律師都可以承接各種不同類型的案件，但如果在特定領域累積一定的案件處理經驗，在處理上較能快速進入狀況，甚至懂得一些與法官應對的眉角！

### 三、與律師諮詢看看吧！
直接與律師諮詢還是最能夠精確瞭解的方式了，透過法律諮詢，可以在溝通的過程中感受到律師對你的案件是否能夠掌握、講解過程中會不會很有壓力，可能導致未來案情溝通不順利等等。

法律010提供線上免費諮詢服務，可以透過Line先跟您想要聯繫的律師溝通初步的案情。

現在就點擊按鈕，立刻找尋適合您的律師吧！
`,

    `## 如何在法律010找律師呢？
在法律010，我們把「找律師」這件事情，變得更方便快速了，5個步驟就能找到適合您的專業律師！
  
 一、點擊下方「立刻搜尋律師」按鈕

 二、選擇您所在的縣市 

 三、查看5個為您嚴選律師的個人檔案 

 四、點擊免費線上諮詢按鈕，立刻開始諮詢！ 

 五、直接委任專業律師或預約進一步的線下諮詢！ `,
  ]

  return (
    <Layout location={location}>
      <GatsbySeo
        title="找律師真行，快來法律010！"
        description="找律師真的很不容易，但法律010把「找律師」這件事情變得簡單又方便了！結合眾多找律師經驗，提供找律師注意事項給您，一步步告訴你如何找律師，順利解決您的法律問題！"
        openGraph={{
          title: "找律師真行，快來法律010！",
          description:
            "找律師真的很不容易，但法律010把「找律師」這件事情變得簡單又方便了！結合眾多找律師經驗，提供找律師注意事項給您，一步步告訴你如何找律師，順利解決您的法律問題！",
        }}
        canonical={`${siteUrl}/find_lawyer`}
      />
      <div className="relative">
        <div className="absolute z-0 block h-full w-full opacity-75 sm:hidden">
          <StaticImage
            src="../images/bill.jpg"
            alt="bill"
            objectFit="cover"
            className="h-full"
            quality={100}
            placeholder={"blurred"}
            loading="eager"
          />
        </div>
        <div
          style={{ height: 426 }}
          className="absolute z-0 hidden w-full opacity-75 sm:block"
        >
          <StaticImage
            src="../images/bill.jpg"
            alt="bill"
            objectFit="cover"
            className="w-full"
            height={426}
            placeholder={"blurred"}
            loading="eager"
          />
        </div>
        <div className="container relative z-10 mx-auto px-6 pb-24 pt-6 text-white md:px-8 xl:px-0">
          <Breadcrumb
            crumbs={crumbs}
            className="-ml-4 text-white md:ml-0"
            activeClassName="text-white"
          />
          <div className="mx-auto mt-6 text-center">
            <h1 className="mx-auto mb-3 text-3xl font-bold md:mb-6 md:text-5xl">
              法律010 找律師真行！
            </h1>
            <p className="text-xl md:text-3xl">現在就找到最適合您的律師</p>
            <div className="mx-auto mt-12 flex flex-col justify-center text-center text-gray-800 sm:max-w-md md:flex-row">
              <select
                className="form-select mb-5 w-full px-3 py-2 sm:mx-auto md:mb-0 md:w-8/12"
                onChange={e => setCity(e.target.value)}
                defaultValue=""
              >
                <option value="" disabled={true} className="select-none">
                  請選擇所在縣市
                </option>
                {cities.map(city => {
                  const { id, title, slug } = city
                  return (
                    <option key={id} value={slug}>
                      {title}
                    </option>
                  )
                })}
              </select>
              <div className="md:ml-5 md:flex md:text-left">
                <Link to={cityUrl}>
                  <Button
                    className="w-full px-24 py-2 text-lg font-medium md:px-4"
                    disabled={!city}
                  >
                    立即查詢
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto divide-y bg-gray-50 px-6 py-16 text-left md:px-8 xl:px-0">
        <RecommendedLawyer
          data={cities}
          destination="city"
          title="根據您的所在地選擇律師"
          excerpt="協助您找到該執業地區中評價最高的律師們"
          about="根據地區推薦律師"
        />
        <RecommendedLawyer
          data={practiceAreas}
          destination="practice_area"
          title="根據執業專長領域選擇律師"
          excerpt="協助您找到該專長領域中評價最高的律師們"
          about="根據專長推薦律師"
          className="pt-16"
        />
      </div>
      <div className="md:pt-18 mx-auto bg-white px-6 pb-12 pt-10 text-left md:px-8 xl:px-0">
        {lawyerBlogs.map((blog, index) => {
          const borderClass =
            index < lawyerBlogs.length - 1 ? "border-b border-gray-300" : ""
          return (
            <div
              className={`container mx-auto ${borderClass}`}
              key={`blog_${index}`}
            >
              <ReactMarkdown className="markdown my-6">{blog}</ReactMarkdown>
              <div className="mx-auto text-center">
                <Link to="/explore">
                  <Button className="findLocation my-12 px-8 py-3 text-lg font-bold text-white md:text-2xl">
                    立即搜尋律師
                  </Button>
                </Link>
              </div>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

FindLawyer.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export default FindLawyer
