import PropTypes from "prop-types"
import React from "react"

const Button = ({ children, className, ...props }) => {
  return (
    <button
      {...props}
      className={`focus:shadow-outline-indigo rounded-md border border-transparent bg-gold-500 px-4 py-2 text-center text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-gold-400 focus:border-gold-600 focus:outline-none active:bg-gold-600 ${className}`}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

export default Button
