import React from "react"
import PropTypes from "prop-types"

const CardHeader = ({ children, className = "" }) => (
  <div className={`border-b border-gray-200 px-4 py-5 sm:px-6 ${className}`}>
    {children}
  </div>
)

const CardTitle = ({ children, className = "" }) => {
  return (
    <>
      <div
        className={`pl-6 pt-6 text-2xl font-medium text-gray-800 ${className}`}
      >
        {children}
      </div>
      <hr className="m-4 border-gold-500 lg:mx-6" />
    </>
  )
}

const CardContent = ({ children, className = "" }) => (
  <div className={`px-4 py-5 lg:p-6 ${className}`}>{children}</div>
)

const CardFooter = ({ children, className = "" }) => {
  return (
    <div className={`border-t border-gray-200 px-4 py-4 lg:px-6 ${className}`}>
      {children}
    </div>
  )
}

const Card = ({ children, className = "", edge = false }) => {
  return (
    <div
      className={`overflow-hidden bg-white shadow-lg ${
        edge ? "lg:rounded-lg" : "rounded-lg"
      } ${className}`}
    >
      {children}
    </div>
  )
}

CardHeader.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

CardTitle.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

CardContent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

CardFooter.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

Card.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  edge: PropTypes.bool,
}

Card.Header = CardHeader
Card.Title = CardTitle
Card.Content = CardContent
Card.Footer = CardFooter

export default Card
