import { useEffect, useRef, useState } from "react"

const useScrollPosition = () => {
  const [scrollToBottom, setScrollToBottom] = useState(false)
  const scrollBlockRef = useRef(null)

  const handleScroll = () => {
    const windowLength = document.body.offsetHeight // 電腦視窗的高度
    const position = window.pageYOffset // 目前捲動位置距離網頁最上方的距離
    const scrollBlockHeight =
      scrollBlockRef.current.clientHeight + scrollBlockRef.current.offsetTop
    // 目前百分比 (分母為部落格文章長度）
    const percentage = ((position + windowLength) / scrollBlockHeight) * 100

    if (percentage > 100) {
      setScrollToBottom(true)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return { scrollToBottom, scrollBlockRef }
}

export default useScrollPosition
