import React from "react"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { useMetadata } from "../data/useMetadata"
import LawyerGroup from "../components/LawyerGroup"
import PropTypes from "prop-types"

export const pageQuery = graphql`
  query PracticeArea($slug: String!) {
    contentfulPracticeArea(slug: { eq: $slug }) {
      slug
      title
      lawyer {
        order
        slug
        name
        lineLink
        isPublished
        introduction {
          introduction
        }
        profile {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            width: 128
            height: 128
          )
        }
      }
    }
  }
`

const PracticeArea = ({ data }) => {
  const { contentfulPracticeArea: practiceArea } = data
  const { lawyer: lawyers, title } = practiceArea

  const { siteUrl } = useMetadata()

  const seo = (
    <GatsbySeo canonical={`${siteUrl}/practice_area/${practiceArea.slug}`} />
  )
  const isPublishedList = lawyers
    .filter(lawyer => lawyer.isPublished)
    .sort((a, b) => a.order - b.order)

  return <LawyerGroup title={title} lawyers={isPublishedList} seo={seo} />
}

PracticeArea.propTypes = {
  data: PropTypes.object,
}

export default PracticeArea
