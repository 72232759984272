import React from "react"
import { BreadcrumbJsonLd } from "gatsby-plugin-next-seo"
import { Link } from "gatsby"
import { useMetadata } from "../data/useMetadata"
import PropTypes from "prop-types"

const Breadcrumb = ({ crumbs, className, activeClassName }) => {
  const { siteUrl } = useMetadata()

  const bread = crumbs.map((crumb, index) => {
    const { crumbLabel, pathname } = crumb
    const spanClass =
      index === crumbs.length - 1 ? `${activeClassName || "text-gray-500"}` : ""
    return (
      <Link key={pathname} to={pathname}>
        {index !== 0 ? <span className="px-2"> / </span> : ""}
        <span className={spanClass}>{crumbLabel}</span>
      </Link>
    )
  })
  const breadcrumbJsonLd = crumbs.map((crumb, index) => {
    const { crumbLabel, pathname } = crumb
    const destination = index === 0 ? "" : pathname

    return {
      position: index + 1,
      name: crumbLabel,
      item: `${siteUrl}${destination}`,
    }
  })

  return (
    <div className="container mx-auto py-4 pl-4 sm:pl-0">
      <BreadcrumbJsonLd itemListElements={breadcrumbJsonLd} />
      <p className={`${className || "text-gray-400"}`}>{bread}</p>
    </div>
  )
}

Breadcrumb.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
}

export default Breadcrumb
