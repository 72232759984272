import { graphql, useStaticQuery } from "gatsby"
import { useMetadata } from "./useMetadata"
export const usePeople = () => {
  const { gtmId } = useMetadata()
  // 給民眾的資訊
  // todo 等之後上線再把 allContentfulTemp 的資料表名字修改成 allContentfulContactInformation
  const data = useStaticQuery(graphql`
    {
      allContentfulTemp(filter: { type: { ne: "lawyer" } }) {
        edges {
          node {
            type
            lineId
            lineAt
            title
            nameOne
            nameTwo
            ctaGtmId: gtmId
            gtmClass
            content {
              content
            }
            qrCode {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)
  let peopleContactInformation
  data.allContentfulTemp.edges.forEach(cta => {
    if (gtmId === cta.node.ctaGtmId) {
      peopleContactInformation = cta.node
    }
  })
  return { peopleContactInformation }
}
