import React, { useEffect, useState } from "react"
import { createClient } from "contentful"
import BlogPost from "../../components/BlogPost"
import Layout from "../../components/Layout"
import Loading from "../../components/Loading"
import PropTypes from "prop-types"
import Breadcrumb from "../../components/Breadcrumb"

const PreviewPost = ({ location }) => {
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)
  const [slug, setSlug] = useState(null)
  const isLoading = !result && !error
  const isBrowser = typeof window !== "undefined"

  const client = createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
    accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
  })

  const fetchPost = async slug => {
    const result = await client.getEntry(slug)
    try {
      if (result) {
        setResult(result)
      }
    } catch (err) {
      setError(err)
    }
  }

  useEffect(() => {
    if (isBrowser) {
      const search = window.location.search
      const id = new URLSearchParams(search).get("id")
      setSlug(id)
    }
  }, [isBrowser, slug])

  useEffect(() => {
    if (slug) {
      fetchPost(slug)
    }
  }, [slug])

  if (!isLoading && !error) {
    const image = (
      <img
        alt={result.fields.cover.fields.title}
        src={result.fields.cover.fields.file.url}
      />
    )

    const crumbs = [
      { pathname: `/`, crumbLabel: "首頁" },
      {
        pathname: `/blog/${result.fields.topic.fields.slug}/`,
        crumbLabel: result.fields.topic.fields.title,
      },
      {
        pathname: `/blog/${result.fields.topic.fields.slug}/${result.fields.tag.fields.slug}/`,
        crumbLabel: result.fields.tag.fields.title,
      },
    ]

    return (
      <Layout location={location}>
        <Breadcrumb crumbs={crumbs} />
        <BlogPost post={result.fields} image={image} />
      </Layout>
    )
  }

  return <Loading />
}

PreviewPost.propTypes = {
  location: PropTypes.object,
}

export default PreviewPost
