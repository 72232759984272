import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import BlogPost from "../components/BlogPost"
import Layout from "../components/Layout"
import Breadcrumb from "../components/Breadcrumb"
import FeaturedPost from "../components/FeaturedPost"
import PostCollection from "../components/PostCollection"
import { usePost } from "../data/usePost"
import PropTypes from "prop-types"
import Author from "../components/Author"

export const pageQuery = graphql`
  query PostQuery($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      slug
      title
      updatedAt(formatString: "YYYY-MM-DD")
      isPublic
      isPublished
      author {
        name
        image {
          gatsbyImageData
        }
        description {
          description
        }
      }
      excerpt {
        excerpt
      }
      tag {
        title
        slug
      }
      cover {
        title
        gatsbyImageData(quality: 100, placeholder: BLURRED)
        file {
          url
        }
      }
      topic {
        slug
        title
        tag {
          id
          title
          slug
          topic {
            slug
            title
          }
        }
      }
      seo {
        metaTitle
        metaDescription
      }
      isConversionPost
      contents {
        id
        title
        displayTitle
        content {
          content
        }
        cta {
          title
          link
          gtm
          gtmClass
        }
      }
    }
  }
`

const PostTemplate = ({ pageContext, location, data }) => {
  const { contentfulPost: post } = data
  const { contents, featuredPostList, popularPostList } = pageContext
  const { isConversionPost } = post
  const { latestPosts } = usePost()

  const image = (
    <GatsbyImage
      alt={post.cover.title}
      image={post.cover.gatsbyImageData}
      loading="eager"
    />
  )

  const crumbs = [
    { pathname: `/`, crumbLabel: "首頁" },
    { pathname: `/blog/${post.topic.slug}/all`, crumbLabel: post.topic.title },
    {
      pathname: `/blog/${post.topic.slug}/${post.tag.slug}`,
      crumbLabel: post.tag.title,
    },
  ]

  return (
    <Layout location={location} isConversionPost={isConversionPost}>
      <Breadcrumb crumbs={crumbs} />
      <BlogPost
        post={post}
        contents={contents}
        image={image}
        isConversionPost={isConversionPost}
      />
      <Author
        author={post.author}
        className="container mx-auto mb-6 sm:mb-12 lg:hidden"
      />
      <FeaturedPost featuredPosts={featuredPostList} />
      <PostCollection title={"熱門文章"} posts={popularPostList} />
      <PostCollection title={"最新文章"} posts={latestPosts.nodes} />
    </Layout>
  )
}

PostTemplate.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  data: PropTypes.object,
}

export default PostTemplate
