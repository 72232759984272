import React from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight"
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft"
import Arrow from "./Arrow"

const CooperativeLawsSlider = ({ data }) => {
  const arrowStyle = "bg-gray-100 text-gray-500 hover:bg-gray-200"
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    rows: 3,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    centerMode: false,
    variableWidth: false,
    prevArrow: (
      <Arrow
        arrowIcon={FaArrowLeft}
        arrowClass={`right-28 -bottom-24 ${arrowStyle}`}
      />
    ),
    nextArrow: (
      <Arrow
        arrowIcon={FaArrowRight}
        arrowClass={`right-10 -bottom-24 ${arrowStyle}`}
      />
    ),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }
  const sliderItems = data.map((item, index) => {
    return (
      <div key={index} className="mb-12 h-12 px-6">
        <img src={item.url} alt={item.title} className="h-full w-full" />
      </div>
    )
  })
  return (
    <Slider {...settings} className="mx-6">
      {sliderItems}
    </Slider>
  )
}

CooperativeLawsSlider.propTypes = {
  data: PropTypes.array,
}

export default CooperativeLawsSlider
