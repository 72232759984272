import React from "react"
import PropTypes from "prop-types"

const SectionTitle = ({ children }) => {
  return (
    <div className="flex items-center">
      <div className="my-3 flex-shrink-0 pr-4 text-lg font-semibold tracking-wider text-gold-500">
        {children}
      </div>
      <div className="flex-1 border-t border-gray-200" />
    </div>
  )
}
const SectionContent = ({ children }) => {
  return <div className="text-gray-800">{children}</div>
}
const Section = ({ children }) => <>{children}</>

SectionTitle.propTypes = {
  children: PropTypes.any,
}
SectionContent.propTypes = {
  children: PropTypes.any,
}
Section.propTypes = {
  children: PropTypes.any,
}

Section.Title = SectionTitle
Section.Content = SectionContent

export default Section
