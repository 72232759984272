import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useEmptyPostTag } from "../data/useEmptyPostTag"

const TagsCloud = ({ tags = [] }) => {
  const nullList = useEmptyPostTag()
  const tagList = tags.map((tag, index) => {
    if (nullList.includes(tag.slug)) {
      return null
    }
    const isLast = tags.length - 1 === index
    return (
      // todo: 觀察tag點擊次數用的追蹤id，未來不需要時移除
      <Link
        key={tag.id}
        to={`/blog/${tag.topic.slug}/${tag.slug || tag.fields.slug}`}
        className="mt-2 inline-block text-gray-500 hover:text-gray-600"
        activeClassName="font-semibold text-gold-400"
        id="tag-btn"
      >
        {tag.title || tag.fields.title}
        <span className={`m-2 ${isLast ? "hidden" : ""}`}>|</span>
      </Link>
    )
  })
  return <>{tagList}</>
}

TagsCloud.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object),
}

export default TagsCloud
