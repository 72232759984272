import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import { useTopicsTags } from "../data/useTopicsTags"
import PropTypes from "prop-types"
import TopicCard from "../components/TopicCard"
import Article from "../components/Article"
import LawyerSlider from "../components/LawyerSlider"
import LineButton from "../components/LineButton"

export const query = graphql`
  {
    platforms: allContentfulPlatform(sort: { order: ASC }, limit: 4) {
      nodes {
        id
        title
        amount
      }
    }
    lawyers: allContentfulLawyer(sort: { order: ASC }, limit: 6) {
      nodes {
        slug
        name
        order
        introduction {
          introduction
        }
        profile {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            width: 128
            height: 128
          )
        }
        practiceAreas {
          title
        }
      }
    }
    latestPosts: allContentfulPost(
      limit: 6
      sort: { updatedAt: DESC }
      filter: { isPublished: { eq: true }, isPublic: { eq: true } }
    ) {
      nodes {
        ...postData
      }
    }
    featuredPosts: allContentfulPost(
      limit: 5
      sort: { updatedAt: DESC }
      filter: {
        isPublished: { eq: true }
        isPublic: { eq: true }
        rank: { eq: 1 }
      }
    ) {
      nodes {
        ...postData
      }
    }
  }

  fragment postData on ContentfulPost {
    id
    slug
    title
    excerpt {
      excerpt
    }
    topic {
      slug
    }
    tag {
      slug
      title
    }
    cover {
      gatsbyImageData
    }
  }
`

const IndexPage = ({ location, data }) => {
  const { platforms, lawyers, latestPosts, featuredPosts } = data

  const { allContentfulTopic } = useTopicsTags()
  const topics = allContentfulTopic.nodes

  const topicCards = topics.map((topic, index) => {
    return <TopicCard key={index} topic={topic} />
  })
  const platformList = platforms.nodes.map((platform, index) => {
    const { amount, title } = platform

    return (
      <div
        key={index}
        className="py-12 text-center font-bold leading-normal text-white"
      >
        <p className="text-2xl">{title}</p>
        <p className="teko-font text-5xl">{amount}</p>
      </div>
    )
  })
  const featuredPostList = featuredPosts.nodes.map((post, index) => {
    return (
      <Article
        post={post}
        key={index}
        index={index + 1}
        displayNumber={true}
        isVertical={false}
      />
    )
  })
  const latestPostList = latestPosts.nodes.map((post, index) => {
    return (
      <Article
        post={post}
        key={index}
        index={index + 1}
        displayNumber={false}
        isVertical={true}
      />
    )
  })

  return (
    <Layout location={location}>
      <div className={`relative overflow-hidden bg-white`}>
        <div className="lg:hidden">
          <StaticImage
            src="../images/img_index_hero.jpg"
            alt={"hero"}
            className="h-48 sm:h-96"
            objectPosition="top"
            objectFit="cover"
          />
        </div>
        <div className="px-6 sm:px-8">
          <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2">
            <div className="pb-16 pt-12 sm:pb-24 lg:py-40">
              <h1 className="my-auto mb-3 text-center text-3xl font-extrabold text-gray-700 sm:mb-6 sm:text-5xl md:text-5xl lg:text-left">
                <span className="font-black text-gold-500">法律010 </span>
                找律師真行！
              </h1>
              <p className="mb-6 text-center text-xl text-gray-600 sm:text-3xl md:text-3xl lg:text-left">
                全台灣最大的免費法律諮詢服務
              </p>
              <div className="flex items-center justify-center lg:justify-start">
                <LineButton text="加LINE立即免費諮詢" />
              </div>
            </div>
            <div className="hidden h-full lg:absolute lg:right-1/2 lg:block lg:w-1/2 lg:translate-x-full">
              <StaticImage
                src="../images/img_index_hero.jpg"
                alt={"hero"}
                className="h-full  object-top"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-neutral-50 px-6 py-16 sm:px-8 sm:py-24">
        <div className="container mx-auto grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-12 lg:grid-cols-3">
          {topicCards}
        </div>
      </div>
      <div className="bg-gold-500 px-6 py-4 sm:px-8 sm:py-20">
        <div className="container mx-auto grid max-w-xs grid-cols-1 divide-y-4 divide-gold-700 lg:max-w-full lg:grid-cols-3 lg:divide-x-4 lg:divide-y-0 ">
          {platformList}
        </div>
      </div>
      <div className="bg-white px-6 py-16 sm:px-8 sm:py-24">
        <div className="container mx-auto">
          <p className="mb-12 text-center text-3xl font-bold text-gray-700">
            熱門文章
          </p>
          <div className="grid grid-cols-1 gap-y-12">{featuredPostList}</div>
        </div>
      </div>
      <div className="bg-neutral-50 py-16 sm:py-24 lg:px-12">
        <div className="lg:container lg:mx-auto">
          <p className="mb-12 text-center text-3xl font-bold text-gray-700">
            合作律師
          </p>
          <LawyerSlider data={lawyers.nodes} />
        </div>
      </div>
      <div className="bg-white px-6 py-16 sm:px-8 sm:py-24">
        <div className="container mx-auto">
          <p className="mb-12 text-center text-3xl font-bold text-gray-700">
            最新文章
          </p>
          <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
            {latestPostList}
          </div>
        </div>
      </div>
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export default IndexPage
